import React from "react";
import { OutboundLink } from "gatsby-plugin-google-gtag";

import PageLayout from "../components/pagelayout";
import Seo from "../components/seo";

import {
  projects,
  project,
  title,
  description,
  link,
} from "./scss/projects.module.scss";

const ProjectsPage = () => (
  <PageLayout
    subTitle="Projects"
    subtitleHref="projects"
    hiddenFooterLinks={["projects"]}
  >
    <Seo title="Projects - Maroun Baydoun" withTitleTemplate={false} />
    <section className={projects}>
      <article className={project}>
        <div className={title}>East West Berlin</div>
        <p className={description}>
          Find out if you're in former East or West Berlin.
        </p>
        <OutboundLink
          href="https://eastwestberlin.com/"
          rel="external"
          eventlabel="eastwestberlin"
          className={link}
        >
          https://eastwestberlin.com/
        </OutboundLink>
      </article>
      <article className={project}>
        <div className={title}>Dekly</div>
        <p className={description}>
          Look up the gender and declensions of German nouns.
        </p>
        <OutboundLink
          href="https://dekly.app/"
          rel="external"
          eventlabel="dekly"
          className={link}
        >
          https://dekly.app/
        </OutboundLink>
      </article>
      <article className={project}>
        <div className={title}>Five Seven Five</div>
        <p className={description}>Craft beautiful haikus with ease.</p>
        <OutboundLink
          href="https://5-7-5.app/"
          rel="external"
          eventlabel="5-7-5"
          className={link}
        >
          https://5-7-5.app/
        </OutboundLink>
      </article>
    </section>
  </PageLayout>
);

export default ProjectsPage;
